






























































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import Service from "@/components/Service.vue";
import Portfolio from "@/components/Portfolio.vue";
import FooterInfo from "@/components/FooterInfo.vue";
import Navigation from "@/components/Navigation.vue";

@Component({
  components: { Navigation, FooterInfo, Portfolio, Service },
})
export default class Home extends Vue {
  // if desktop or not
  isDesktop() {
    return this.$vuetify.breakpoint.lgAndUp;
  }
}
