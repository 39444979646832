import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#181D27",
        secondary: "#FCDA68",
        grey2: "#A7B8BF",
      },
    },
  },
});
