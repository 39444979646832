











































































import { Component, Vue } from "vue-property-decorator";
import FooterInfo from "@/components/FooterInfo.vue";

@Component({
  components: { FooterInfo },
})
export default class Footer extends Vue {
  // if desktop or not
  isDesktop() {
    return this.$vuetify.breakpoint.lgAndUp;
  }

  // open link
  open() {
    //window.open(this.link);
  }
}
