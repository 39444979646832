


























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class FooterInfo extends Vue {
  @Prop({}) readonly icon!: string;
  @Prop({}) readonly text!: string;
  @Prop({}) readonly link!: string;

  // if desktop or not
  isDesktop() {
    return this.$vuetify.breakpoint.lgAndUp;
  }

  // open link
  open() {
    window.open(this.link);
  }
}
