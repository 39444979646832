


























import { Component, Vue } from "vue-property-decorator";

@Component({
  props: {
    illustration: String,
    icon: String,
    color: String,
    heading: String,
  },
})
export default class Portfolio extends Vue {
  // if desktop or not
  isDesktop() {
    return this.$vuetify.breakpoint.lgAndUp;
  }
}
