import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import InlineSvg from "vue-inline-svg";
import VueScrollTo from "vue-scrollto";
// @ts-ignore
import VueGlow from "vue-glow";

// @ts-ignore
import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css";

import "@/scss/_global.scss";
import "@/scss/_font.scss";

Vue.config.productionTip = false;
Vue.component("inline-svg", InlineSvg);
Vue.component("vue-glow", VueGlow);
Vue.use(Vuesax);
Vue.use(VueScrollTo);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
